import React from 'react'
import './teacher.scss'

const Teacher = ({header, edu, about, exp1, exp2, pic}) => {
  return (
    <div id='teacher'>
      <div className='left-side'>
        <img src={pic} alt="teacher" />
      </div>
      <div className='right-side'>
        <h3>{header}</h3>
        <p>{about}</p>
        <p>{edu}</p>
        <p>{exp1}</p>
        <p>{exp2}</p>
      </div>
    </div>
  )
}

export default Teacher