import React, { useState } from 'react'
import Card from './cards/Card'
import styles from "./Sections.module.scss"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";


const Courses = () => {

    const navigate = useNavigate()
    return (
        <main className={styles.courses}>
            <div className={styles.heading}>
                <h2>Kurslarımız</h2>
                <p>Tədris etdiyimiz kurslar</p>
            </div>
            <div className={styles.courseCards}>
                <div className={styles.courseDiv}>
                    <Card classname={styles.coursecard} heading="Turmenecer (getmə) proqramı" />
                    <Link className={styles.button} to="kurslar/turmenecer">Ətraflı</Link>
                </div>
                <div className={styles.courseDiv}>
                    <Card classname={styles.coursecard} heading="Viza Mütəxəssisi (bütün dünya)" />
                    <Link className={styles.button} to="kurslar/viza">Ətraflı</Link>
                </div>
                <div className={styles.courseDiv}>
                    <Card classname={styles.coursecard} heading="Turizmdə marketinq" />
                    <Link className={styles.button} to="kurslar">Ətraflı</Link>
                </div>
            </div>
            <button onClick={()=> navigate('kurslar')} className={styles.buttonEnd}>Hamısına bax</button>
        </main>
    )
}

export default Courses