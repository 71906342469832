import React from 'react'
import styles from "./Sections.module.scss"
import Card from "./cards/Card"
import Rec1 from "../../assets/images/Rectangle376.png"
import Rec2 from "../../assets/images/Rectangle375.png"
import cardImage from "../../assets/images/CardImage.png"

const About = () => {
    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.recsContainer}>
                    <img className={styles.rec1} src={Rec1} alt="rectangle" />
                    <img className={styles.rec2} src={Rec2} alt="rectangle" />
                </div>
                <div className={styles.containerAbout}>
                    <div className={styles.aboutImg}>
                        <img className={styles.background} src={cardImage} alt="img" />
                    </div>
                    <div className={styles.aboutUs}>
                        <Card classname={styles.card} heading="Niyə məhz Turizm.Edu.az?" parag='"Turizm.Edu.Az" komandası olaraq həyatımızın böyük və gözəl parçasını turizm işini öyrənməyə həsr etmişik. İndi isə öyrəndiyimiz bütün praktik və nəzəri bilikləri Səninlə paylaşmaq, Sənə turizm sferasında səviyyəli xidmət göstərməyi öyrətmək ən böyük amalımızdır.' button="Ətraflı" link={"/haqqimizda"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About