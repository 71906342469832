import React from 'react'
import styles from "./Sections.module.scss"
import Card from './cards/Card'
import img1 from "../../assets/images/blog1.jpg"
import img2 from "../../assets/images/blog2.jpg"
import img3 from "../../assets/images/blog3.jpg"
import { Link } from 'react-router-dom'

const News = () => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h2>Bloglar və media</h2>
        <p>Bunları bilirdiniz mi?</p>
        <div className={styles.blogs}>
            <Card button="Səyahət agentləri kimdir və nə edirlər?" img={img1} classname={styles.card} link="https://www.instagram.com/p/ChUVzUWjXsX/" />
            <Card button="Təcrübəsiz Turmenecerlərin 9 səhvi" img={img2} classname={styles.card} link="https://www.instagram.com/p/Cfefs3Cjszs/"/>
            <Card button="Kimdir Turmenecer?" img={img3} classname={styles.card} link="https://www.instagram.com/p/Ceeb3lRLP2-/" />
        </div>
      </div>
    </div>
  )
}

export default News