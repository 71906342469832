import React, { useState, useEffect, useRef } from "react";
import ArrowIcon from '../../../../assets/images/down-arrow.svg'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function List({ text, subMenu, show, setShow, link, page }) {
    const [showSub, setShowSub] = useState(false);
    const ref = useRef()
    const navigate = useNavigate()

    const showSubCategory = () => {
        setShowSub(!showSub)
    }

    const clickMenu = () => {
        setShow(false)
    }

    const handleClick = () => {
        if(page) {
            navigate(page)
            setShow(false)
        }
    }

    useEffect(() => {
        if (!show && ref.current) {
            ref.current.classList.remove('button-list_active')
            setShowSub(false)
        }
    }, [show])

    return (
        <li className="list" onClick={handleClick}>
            <p className="button-item" onClick={showSubCategory} >{text}
                {
                    subMenu &&
                    <img src={ArrowIcon} alt="arrow" className={showSub ? "arrow-active" : undefined} />
                }
            </p>
            {
                subMenu &&
                <ul ref={ref} className={showSub ? "button-list button-list_active" : "button-list"}>
                    {
                        subMenu.map((sub, index) => (
                                link ?
                                <Link key={index} to={`courses/${index+1}`}>
                                    <li onClick={clickMenu} className="button-list-item" >{sub}</li>
                                </Link>
                                :
                                <li className="button-list-item" key={index}>{sub}</li>
                        ))}
                </ul>
            }
        </li>
    )
}

export default List