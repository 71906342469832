import React from 'react'
import './teachers.scss'
import Teacher from '../../components/Teachers/Teacher'
import Vusale from '../../assets/images/vusale.jpeg'
import Ulfane from '../../assets/images/ulfane.jpeg'
import Sebnem from '../../assets/images/sebnem.jpeg'

const TeacherPage = () => {
  return (
    <div id='teachers'>
        <Teacher header={'Vüsalə Məmmədzadə'} about={'1992-ci ildə Bakı şəhərində anadan olmuşdur.'} edu={'Təhsili: Azərbaycan Turizm və Menecment Universiteti-nin Turizm və Hotelçilik fakültəsinin məzunudur. Müvafiq sahə üzrə bir çox beynəlxalq və yerli seminar və ixtisas artırma təlimlərində iştirak etmişdir.'} exp1={'İş təcrübəsi: Respublikanın aparıcı turizm şirkətlərində 10 ildən artıq davam edən iş təcrübəsinə sahibdir.'} exp2={'Təlimçilik təcrübəsi: Turizm meneceri, Viza mütəxəssisi və Aviakassir təlimləri üzrə 8 illik təcrübəyə malikdir.'} pic={Vusale}/>
        <Teacher header={'Ülfanə Mahmudova'} about={'1986-ci ildə Şəki şəhərində anadan olmuşdur.'} edu={'Təhsili: Bakı Dövlət Universitetinin Turizm və Hotelçilik fakültəsinin magistr məzunudur. Müvafiq sahə üzrə bir çox beynəlxalq və yerli seminar və ixtisas artırma təlimlərində iştirak etmişdir.'} exp1={'İş təcrübəsi: Respublikanın aparıcı turizm şirkəti olan Turizm.Az-da 14 ildən artıq davam edən iş təcrübəsinə sahibdir.'} exp2={'Təlimçilik təcrübəsi: Turizm meneceri, Viza mütəxəssisi və Aviakassir təlimləri üzrə 10 illik təcrübəyə malikdir.'} pic={Ulfane}/>
        <Teacher header={'Şəbnəm Qarayeva'} about={'1995-ci ildə Bakıda anadan olub.'} edu={'Təhsili: Azərbaycan Dillər Universitetinin 2016-cı il məzunudur.'} exp1={'İş təcrübəsi: 7 ildir ki, turizm sahəsində çalışır. İtaliyada Milan Ticarət, Sənaye, Sənətkarlıq və Kənd Təsərrüfatı Palatasının layihəsində təcrübə programı keçmişdir.'} exp2={'Təlimçilik təcrübəsi: Hazırda Orange Travel şirkətinin təsisçi və direktorudur. Bununla yanaşı bir çox beynəlxalq təlimdə iştirak edib.'} pic={Sebnem}/>
    </div>
  )
}

export default TeacherPage