import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./Modal.scss";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { SendEmail } from '../../API';
import Swal from 'sweetalert2';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const MyVerticallyCenteredModal = (props) => {
  const [course, setCourse] = useState("");
  const [seminar, setSeminar] = useState('')
  const [promoExist, setPromoExist] = React.useState(false)
  const [price, setPrice] = useState(300)
  const [send, setSend] = useState();
  //Loader
  const [open, setOpen] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("E-poçt düzgün deyil")
      .required("Zəhmət olmasa e-poçt daxil edin"),
    name: yup.string().required("Zəhmət olmasa ad və soyadınızı qeyd edin"),
    phone: yup
      .number()
      .integer()
      .required("Zəhmət olmasa əlaqə nömrənizi daxil edin"),
  });

  const handleSelect = (e) => {
    setCourse(e.target.value)
    setPrice(300)
    // if (e.target.value === "Viza Mütəxəssisi - 14 noyabr") {
    //   setPrice(300)
    // }
    // if (e.target.value === "Turmenecer Proqramı - 14 noyabr") {
    //   setPrice(300)
    // }
  }

  const handleSelectSem = (e) => {
    setSeminar(e.target.value)
  }

  useEffect(() => {
    if (send) {
      window.location.reload(true)
    }
  }, [send])


  const onSubmit = (values) => {
    {
      /* Loader */
    }
    setOpen(!open);
    values.course = course;
    values.seminar = seminar;
    console.log(values)

    SendEmail({ nameSurname: values.name, userEmail: values.email, userPhone: values.phone, selectedCourse: values.course, seminar: values.seminar, promoCode: values.promocode, industry: values.industry, membership: values.membership, setSend }).then(
      () => {
        setOpen(false);
        Swal.fire(
          {
            title: 'Müraciətiniz göndərildi!',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
          }
        )
        setTimeout(() => {
          window.location.reload()
        }, 1000);
       
      }
    );
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "+994",
      promocode: "",
      industry: "",
      membership: false
    },
    validationSchema: schema,
    onSubmit,
  });

  return (
    <div>
      <Modal
        className="centered-modal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Müraciət et
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="inputs">
            <div className="left-side-modal">
              <div>
                <label htmlFor="name">Ad, Soyad:</label>
                <input
                  className={errors.name && touched.name ? "input-error" : ""}
                  value={send ? "" : values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Daxil edin"
                  type="text"
                  name="name"
                  id="name"
                />
                {errors.name && touched.name && (
                  <p className="errorMessage">{errors.name}</p>
                )}
              </div>
              <div>
                <label htmlFor="email">E-poçt:</label>
                <input
                  className={errors.email && touched.email ? "input-error" : ""}
                  value={send ? "" : values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Daxil edin"
                  type="email"
                  name="email"
                  id="email"
                />
                {errors.email && touched.email && (
                  <p className="errorMessage">{errors.email}</p>
                )}
              </div>
              <div>
                <label htmlFor="phone">Telefon:</label>
                <input
                  className={errors.phone && touched.phone ? "input-error" : ""}
                  value={send ? "" : values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Daxil edin"
                  type="phone"
                  name="phone"
                  id="phone"
                />
                {errors.phone && touched.phone && (
                  <p className="errorMessage">{"Əlaqə nömrəsi düzgün deyil"}</p>
                )}
              </div>
              <div>
                <label htmlFor="course">Kurs:</label>
                <select
                  onChange={handleSelect}
                  name="course"
                  id="course"
                >
                  <option value="">(Seçilməyib)</option>
                  <option value="Tur menecer (getmə) proqramı">Tur menecer (getmə) proqramı</option>
                  <option value="Viza mütəxəssisi (bütün dünya)">Viza mütəxəssisi (bütün dünya)</option>
                  <option value="Gəlmə turizm">Gəlmə turizm</option>
                  <option value="Daxili turizm">Daxili turizm</option>
                  <option value="Turizmdə mühasibatlıq">Turizmdə mühasibatlıq</option>
                  <option value="Turizmdə marketinq">Turizmdə marketinq</option>
                  <option value="Turizmdə layihələrin idarə edilməsi">Turizmdə layihələrin idarə edilməsi</option>
                  <option value="Turizmdə satış texnikaları">Turizmdə satış texnikaları</option>
                </select>
                {/* <div className="price">
                  <p>Qiymət:</p>
                  <p>{price} AZN</p>
                </div> */}
              </div>
              <div>
                <label htmlFor="course">Seminar:</label>
                <select
                  onChange={handleSelectSem}
                  name="seminar"
                  id="seminar"
                >
                  <option value="">(Seçilməyib)</option>
                  <option value="Viza seminarı - USA">Viza seminarı - USA</option>
                  <option value="Viza seminarı - UK">Viza seminarı - UK</option>
                  <option value="Viza seminarı - Canada">Viza seminarı - Canada</option>
                  <option value="Viza seminarı - EU">Viza seminarı - EU</option>
                  <option value="Turizm seminarı - Amerika və Kruiz turları">Turizm seminarı - Amerika və Kruiz turları</option>
                  <option value="Turizm seminarı - Sanatoriyalar">Turizm seminarı - Sanatoriyalar</option>
                  <option value="Turizm seminarı - Asiya turları">Turizm seminarı - Asiya turları</option>
                  <option value="Turizm seminarı - Avropa turları">Turizm seminarı - Avropa turları</option>
                </select>
              </div>
            </div>
            <div className="right-side-modal">
              <p>Əlavə məlumatlar</p>
              <div>
                <label htmlFor="industry">Şirkət adı:</label>
                <input value={send ? "" : values.industry} onChange={handleChange} placeholder="Daxil edin" type="text" name="industry" id="industry" />
              </div>
              <div>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">ATAA üzvüsünüzmü?</FormLabel>
                  <RadioGroup
                    onChange={(e) => e.target.value === "beli" ? values.membership = true : values.membership = false}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="xeyr"
                    name="membership"
                  >
                    <FormControlLabel value="beli" control={<Radio />} label="Bəli" />
                    <FormControlLabel value="xeyr" control={<Radio />} label="Xeyr" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">Promo kodunuz varmı?</FormLabel>
                  <RadioGroup
                    onChange={(e) => e.target.value === "beli" ? setPromoExist(true) : setPromoExist(false)}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="xeyr"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel value="beli" control={<Radio />} label="Bəli" />
                    <FormControlLabel value="xeyr" control={<Radio />} label="Xeyr" />
                  </RadioGroup>
                </FormControl>
                <div style={promoExist ? { display: 'block' } : { display: "none" }}>
                  <label htmlFor="promocode">Promo kod:</label>
                  <input onChange={handleChange} type="text" value={send ? "" : values.promocode} name="promocode" id="promocode" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className="modalButton"
          >
            Müraciət et
          </Button>
          {/* Loader */}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyVerticallyCenteredModal;

