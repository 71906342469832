import React from "react";
import "./Contact.scss";
import MapIcon from "../../assets/images/Map.png";
import Clock from "../../assets/images/Clock.png";
import Phone from "../../assets/images/Phone.png";
import Mail from "../../assets/images/Mail.png";


const Contact = () => {
  return (
    <div className="main">
      <div className="header">
        <h2>Əlaqə</h2>
        <div className="contactMain">
          <div className="info">
            <div className="infoCard">
              <div className="logoContact">
                <img src={MapIcon} alt="" />
              </div>
              <h5>Ünvan</h5>
              <a href="https://www.google.com/maps/d/viewer?msa=0&mid=1li8r6bm-jRoyDs_cBhcQz4N6TlI&ll=40.385731999999976%2C49.82831&z=17">Cəfər Cabbarlı küçəsi 44, Caspian Plaza</a>
            </div>
            <div className="infoCard">
              <div className="logoContact">
                <img src={Clock} alt="" />
              </div>
              <h5>İş saatı</h5>
              <p>10:00 - 17:00</p>
            </div>
            <div className="infoCard">
              <div className="logoContact">
                <img src={Phone} alt="" />
              </div>
              <h5>Telefon</h5>
              <a href="tel:+994-55-212-51-61">+994 55 212 49 59 </a>
            </div>
            <div className="infoCard">
              <div className="logoContact">
                <img src={Mail} alt="" />
              </div>
              <h5>Email</h5>
              <a href="mailto:office@turizm.edu.az">office@turizm.edu.az</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
