import React from 'react'

const Card = ({img, heading, parag, button, classname, link}) => {
  return (
    <div onClick={() => {link && window.open(link, '_self')}} className={classname}>
        <img src={img} alt="img" />
        <h3>{heading}</h3>
        <p>{parag}</p>
        <button onClick={() => {link && window.open(link, '_self')}}>{button}</button>
    </div>
  )
}

export default Card