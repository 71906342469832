import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import arrowicon from '../../assets/images/arrow.png'
import './sidemenu.scss'
import { useNavigate } from "react-router-dom";
import dropdown from '../../assets/images/dropdown.png'

const drawerWidth = 340;



const seminar = ['Viza seminarı - USA', 'Viza seminarı - UK', 'Viza seminarı - Canada', 'Viza seminarı - EU', 'Turizm seminarı - Amerika və Kruiz turları', 'Turizm seminarı - Sanatoriyalar', 'Turizm seminarı - Asiya turları', 'Turizm seminarı - Avropa turları']

const kurs = ['Tur menecer (getmə) proqramı',
    'Viza mütəxəssisi (bütün dünya)',
    'Gəlmə turizm',
    'Daxili turizm',
    'Turizmdə mühasibatlıq',
    'Turizmdə marketinq',
    'Turizmdə layihələrin idarə edilməsi',
    'Turizmdə satış texnikaları'
]

function ResponsiveDrawer(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [courseOpen, setCourseOpen] = React.useState(false);
    const [seminarOpen, setSeminarOpen] = React.useState(false);
    const navigate = useNavigate()
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleClick = (index) => {
        if (index === 0) {
            navigate('/kurslar/turmenecer')
            setMobileOpen(false)
        }
        if (index === 1) {
            navigate('/kurslar/viza')
            setMobileOpen(false)
        }
        if (index === 3) {
            navigate('/kurslar/daxiliturizm')
            setMobileOpen(false)
        }
        if(index === 2 || index === 4 || index === 5 || index === 6 || index === 7) {
            navigate('/kurslar/tezlikle')
            setMobileOpen(false)
        }
    }

    const drawer = (
        <div className='sidemenu'>
            <Toolbar style={{cursor: 'pointer'}} onClick={() => setCourseOpen(!courseOpen)} >Kurslar <img style={courseOpen ? {width: '16px', opacity: '0.8', marginLeft: '16px', transform:'rotate(180deg)', transition: '0.3s'} : {width: '16px', opacity: '0.8', marginLeft: '16px', transform:'rotate(360deg)', transition: '0.3s'}} src={dropdown} alt="icon" /></Toolbar>
            <Divider />
            <List style={courseOpen ? {display: 'block'} : {display: 'none'}}>
                {kurs.map((text, index) => (
                    <ListItem onClick={() => handleClick(index)} key={text} disablePadding>
                        <ListItemButton>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Toolbar style={{cursor: 'pointer'}} onClick={() => setSeminarOpen(!seminarOpen)}>Seminarlar <img style={seminarOpen ? {width: '16px', opacity: '0.8', marginLeft: '16px', transform:'rotate(180deg)', transition: '0.3s'} : {width: '16px', opacity: '0.8', marginLeft: '16px', transform:'rotate(360deg)', transition: '0.3s'}} src={dropdown} alt="icon" /></Toolbar>
            <Divider />
            <List style={seminarOpen ? {display: 'block'} : {display: 'none'}}>
                {seminar.map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div id='sidemenu'>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <span>Kateqoriya seçin <img src={arrowicon} alt="icon" /></span>
            </IconButton>

            <Box sx={{ display: 'flex' }}>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                {/* <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
          enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
          imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
          Convallis convallis tellus id interdum velit laoreet id donec ultrices.
          Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
          adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
          nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
          leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
          feugiat vivamus at augue. At augue eget arcu dictum varius duis at
          consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
          sapien faucibus et molestie ac.
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography>
      </Box> */}
            </Box>
        </div>

    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;