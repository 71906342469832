import React, { useState } from "react";
import "./About.scss";
import img1 from "../../assets/images/about1.png";
import img2 from "../../assets/images/about2.png";
import img3 from "../../assets/images/about3.png";
import MyVerticallyCenteredModal from "../../components/Modal/CenteredModal";

const About = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="main">
      <div className="header">
        <h2>Haqqımızda</h2>
        <div className="aboutMain">
          <h3>Turizm.Edu.Az</h3>
          <p>
            Turizm.Edu.Az" komandası olaraq həyatımızın böyük və gözəl parçasını
            turizm işini öyrənməyə həsr etmişik. İndi isə öyrəndiyimiz bütün
            praktik və nəzəri bilikləri Səninlə paylaşmaq, Sənə turizm
            sferasında səviyyəli xidmət göstərməyi öyrətmək ən böyük
            amalımızdır. <br /> Səyahət yeni başlanğıclar deməkdir - biz işimizə
            elə peşəkarcasına yiyələnməliyik ki, insanlara ən gözəl
            başlanğıcları hədiyyə edə, səyahət etməyi onların həyatında
            unudulmaz xatirə kimi yaşada bilək. Hər səyahət sorğusuna özümüz
            ilk dəfə səyahət edirmiş kimi yanaşırıq və işimizin
            məsuliyyətinin öhdəsindən sevgi ilə gəlirik. <br />
            <br />
            Sənə də bunu öyrədəcəyik.
            <br />
            <br />
            Səhifədə bizimlə birlikdə həm əylənib, həm öyrənəcəksən. <br />
            Akademiyamıza qoşularaq karyeranda yeni səhifə açmış olacaqsan.
            <br />
            <br />
            Ailəmizə xoş gəldin!
          </p>
        </div>
      </div>
      <div className="sections">
        <div className="section">
          <img src={img1} alt="" />
          <ul>
            <h3>Təlimdə öyrənəcəksiniz:</h3>
            <li>Turizmə giriş</li>
            <li>Əsas partnyorlar və onların sistemləri ilə işləmə qaydası</li>
            <li>Subagent səviyyəsində biletləmə</li>
            <li>Partnyorlarla və müştərilərlə ünsiyyətin qurulması</li>
            <li>Viza haqqında ümumi məlumat</li>
            <li>Transfer, səyahət sığortasının yazılması</li>
            <li>Turmenecerin biznes münasibətlərinin qurulması</li>
            <li>Müştəri portfoliosunun yaradılması yolları</li>
            <li>
              Sosial şəbəkələrdə aktivliyin turmenecer fəaliyyətinə müsbət
              təsirləri
            </li>
          </ul>
        </div>
        <div className="section">
          <img src={img2} alt="" />
          <ul>
            <h3>Təlimin proqramı:</h3>
            <li>3 həftə turmenecer təlimi</li>
            <li>2 həftə təcrübə - 1-ci şirkət</li>
            <li>1 həftə təcrübə - 2-ci şirkət</li>
            <li>Turizm.Az-da 1 həftəlik turizm sertfikasiyası təlimi</li>
            <li>İmtahan və yekun sertfikasiya</li>
          </ul>
        </div>
        <div className="section">
          <img src={img3} alt="" />
          <ul>
            <h3>Kursu müvəffəqiyyətlə (ən yüksək qiymətlə) bitirən tələbələrin əldə etdiyi üstünlüklər:</h3>
            <li>1 nəfərlik aviabilet hədiyyə</li>
            <li>CV bankında şəxsi kabinet</li>
            <li>1 həftəlik infotur hədiyyə - bütün tələbələr</li>
            <li>Turmenecer klubuna üzvlük - bütün tələbələr</li>
          </ul>
        </div>
      </div>
      <div className="button">
        <button onClick={() => setModalShow(true)}>Elə indi müraciət et</button>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
    </div>
  );
};

export default About;
