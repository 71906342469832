import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Course from './Course'
import "./CoursePage.scss"

const CoursePage = () => {

  const { id } = useParams()

  return (
    <div id='coursePage'>
      {
        id === 'daxiliturizm' && (
          <div>
            <p className='text'><h3>Daxili turizm təlimimizə start veririk!</h3> <br /> Daxili turizm meneceri işin təşkilini və gedişatını görməlidir. Əks halda turları düzgün qaydada təşkil edə bilməz! <br /> Bu yanaşmamızı əsas götürərək təlim müddətində hər həftəsonu bir bölgədə turun gedişatında iştirak etmək fürsəti təqdim edirik Sənə! <br /> Bu bizim fərqimizdir - hər birinizə fərdi yanaşırıq! <br /> Bizə sənin təlimdə iştirakın deyil, nəticən önəmlidir. <br /><br />
              <b>Təlimdə öyrənəcəksən:</b>
              <ol>
                <li>Daxili turların mükəmməl təşkil olunması, qiymətləndirmə sistemi</li>
                <li>Bakı və regional otellərlə sistematik işləmə qaydası</li>
                <li>Transport işinin düzgün təşkili</li>
                <li>Restoranlarla əməkdaşlıq qaydaları</li>
                <li>Pareto prinsipi və ona uyğun Smart idarəetmə</li>
                <li>Daxili turizmdə SWOT analiz</li>
                <li>Dövlət orqanları və Korporativlərlə iş prinsipləri</li>
                <li>Tərtib olunmuş proqramların düzgün turizm marketinə təqdim olunması, satışın düzgün təşkil olunması</li>
              </ol>
              <b>Təlimin proqramı:</b>
              <ul>
                <li>4 həftə - daxili turizm təlimi</li>
                <li>Hər həftəsonu təcrübə (bölgə turu)</li>
                <li>Təlimin sonunda 1 gecəlik ölkədaxili infotur</li>
                <li>İmtahan və yekun sertfikasiya</li>
              </ul>
              <b>Kursu bitirən tələbələrə verilən üstünlüklər:</b>
              <ul>
                <li>Bölgə otelində 2 nəfərlik qonaqlama (ilk qrupa özəl)</li>
                <li>CV bankında şəxsi kabinet - yalnız sertifikat alan tələbələr</li>
                <li>Turmenecer klubuna üzvlük - bütün tələbələr</li>
              </ul>
            </p>
          </div>
        )
      }
      {
        id === 'viza' && (
          <div>
            <p className='text'><h3>"Viza mütəxəssisiyəm" təlimimizə start veririk!</h3> <br /> 
            Təlimi uğurla başa vur, yeni layihəmizdə bizimlə birgə çalış! <br />
            Yeni kadrlara həmişə ehtiyacımız var - yetər ki, ölkə turizmimizə töhfə verə bilək! <br /><br />
              <b>Təlimdə öyrənəcəksən:</b>
              <ol>
                <li>Ümumi viza anlayışı, əsas coğrafi biliklər</li>
                <li>Şengen, Sloveniya, Xorvatiya, Bolqarıstan viza dəstəyi</li>
                <li>Amerika, Britaniya, Kanada, Braziliya, Meksika viza dəstəyi</li>
                <li>BƏƏ, Yaponiya, Küveyt, Bəhreyn</li>
                <li>Şri Lanka, Hindistan, Misir viza dəstəyi</li>
                <li>Çin, Tayland, Cənubi Koreya, Sinqapur</li>
                <li>Azərbaycana e-viza</li>
                <li>Sığortaların yazılması, viza müraciəti üçün aviabilet bronu</li>
              </ol>
              <b>Təlimin proqramı:</b>
              <ul>
                <li>3 həftə - viza mütəxəssisi təlimi</li>
                <li>(Təlim həm nəzəri, həm praktik keçirilir deyə əlavə təcrübə proqramına ehtiyac yoxdur)</li>
                <li>İmtahan və yekun sertifikasiya</li>
              </ul>
              <b>Kursu bitirən tələbələrə verilən üstünlüklər:</b>
              <ul>
                <li>Runaway house-da 2 nəfərlik qonaqlama (ilk qrupa özəl)</li>
                <li>CV bankında şəxsi kabinet – yalnız sertifikat alan tələbələr</li>
                <li>Turmenecer klubuna üzvlük – bütün tələbələr</li>
              </ul>
            </p>
          </div>
        )
      }
      {
        id === 'turmenecer' && (
          <div>
            <p className='text'><h3>Turizm.Edu.Az-ın təşkilatçılığı ilə peşəkar və təcrübəli turmenecer təlimlərinə qeydiyyat başladı!</h3> <br /> <br /> 
              <b>Təlimdə öyrənəcəksən:</b>
              <ol>
                <li>Turizmə giriş</li>
                <li>Əsas partnyorlar və onların sistemləri ilə işləmə qaydası</li>
                <li>Subagent səviyyəsində biletləmə</li>
                <li>Partnyorlarla və müştərilərlə ünsiyyətin qurulması</li>
                <li>Viza haqqında ümümi məlumat</li>
                <li>Transfer, səyahət sığortasının yazılması</li>
                <li>Turmenecerin biznes münasibətlərinin qurulması</li>
                <li>Müştəri portfoliosunun yaradılması yolları</li>
                <li>Sosial şəbəkələrdə aktivliyin turmenecer fəaliyyətinə müsbət təsirləri</li>
                <li>Turizmdə mühasibatlıq</li>
                <li>Turizmdə marketinq</li>
                <li>Turizmdə layihələrin idarə edilməsi</li>
                <li>Turizmdə satış texnikaları</li>
              </ol>
              <b>Təlimin proqramı:</b>
              <ul>
                <li>3 həftə - turmenecer təlimi</li>
                <li>2 həftə təcrübə - 1-ci şirkət</li>
                <li>1 həftə təcrübə - 2-ci şirkət</li>
                <li>İmtahan və yekun sertifikasiya</li>
              </ul>
              <b>Kursu bitirən tələbələrə verilən üstünlüklər:</b>
              <ul>
                <li>1 nəfərlik aviabilet hədiyyə - qrup lideri</li>
                <li>CV bankında şəxsi kabinet - sertifikat alan tələbələr</li>
                <li>1 həftəlik infotur hədiyyə - bütün tələbələr</li>
                <li>Turmenecer klubuna üzvlük – bütün tələbələr</li>
              </ul>
            </p>
          </div>
        )
      }
      {
        id === 'tezlikle' && (
          <div style={{height:'100%',width: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h3>(Tezliklə)</h3>
          </div>
        )
      }
    </div>
  )
}

export default CoursePage