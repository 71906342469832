import React from 'react'
import styles from "./Sections.module.scss"
import Card from "./cards/Card"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/autoplay';
import 'swiper/scss/effect-fade';
import Sebnem from "../../assets/images/sebnem.jpeg"
import Vusale from "../../assets/images/vusale.jpeg"
import Ulfane from "../../assets/images/ulfane.jpeg"

const Teachers = () => {
    return (
        <Swiper
            className={styles.teachers}
            modules={[Autoplay]}
            effect="fade"
            autoplay={{ delay: 5000 }}
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
                640: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
            }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
        >
            <SwiperSlide><Card classname={styles.teacherCard} heading={"Vüsalə Məmmədzadə"} button={"Ətraflı məlumat"} img={Vusale} link={"/telimciler"} /></SwiperSlide>
            <SwiperSlide><Card classname={styles.teacherCard} heading={"Ülfanə Mahmudova"} button={"Ətraflı məlumat"} img={Ulfane} link={"/telimciler"} /></SwiperSlide>
            <SwiperSlide><Card classname={styles.teacherCard} heading={"Şəbnəm Qarayeva"}  button={"Ətraflı məlumat"} img={Sebnem} link={"/telimciler"}/></SwiperSlide>
        </Swiper>
    )
}

export default Teachers