import React from 'react'
import './Sponsors.scss'
import Turizm from '../../assets/images/turizm.az.png'
import Runaway from '../../assets/images/runaway.png'
import Turkish from '../../assets/images/turkish.png'
import Rtravel from '../../assets/images/Rtravel.png'
import Accounting from '../../assets/images/accounting.png'

const Sponsors = () => {
  return (
    <div className="main">
      <div className="header">
        <h2>Partnyorlarımız</h2>
        <div className="sponsors">
        <img onClick={() => window.open('https://www.turkishairlines.com/', '_blank')} className='turkishpng' src={Turkish} alt="logo" />
        <img onClick={() => window.open('https://turizm.az/', '_blank')} src={Turizm} alt="logo" />
        <img onClick={() => window.open('https://www.runaway.house/', '_blank')} className='runawaypng' src={Runaway} alt="logo" />
        <img onClick={() => window.open('https://accounting.az/', '_blank')} src={Accounting} alt="logo" />
        </div>
      </div>
    </div>
  )
}

export default Sponsors