import React, { useState, useEffect } from "react";
import List from "./list/List";
import Logo from "../../../assets/images/logoTransparent.png";
import "./Navbar.scss";
import MyVerticallyCenteredModal from "../../Modal/CenteredModal";
import { Link } from "react-router-dom";
import Ataa from '../../../assets/images/ataa.png'

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [show]);

  return (
    <header className="Navbar">
      <div className="Logo">
        <ul
          onClick={() => setShow(!show)}
          className={show ? "burger-icon burger-icon_active" : "burger-icon"}
        >
          <li className="burger-line"></li>
          <li className="burger-line"></li>
          <li className="burger-line"></li>
        </ul>
        <Link to="/">
          <img title="Əsas səhifə" src={Logo} alt="logo" />
        </Link>
        <img onClick={() => window.open('https://www.ataa.az/', '_blank')} className="ataa" src={Ataa} alt="ataa" />
      </div>

      {/*Navbar starts*/}
      <div className="right-side">
        <div className={show ? "Nav nav-active" : "Nav"}>
          <ul className="Buttons">
          <List
              setShow={setShow}
              text={"Partnyorlar"}
              show={show}
              page={"/partnyorlar"}
            />
            <List
              setShow={setShow}
              text={"Haqqımızda"}
              show={show}
              page={"/haqqimizda"}
            />
            <List
              setShow={setShow}
              text={"Təlimçilərimiz"}
              show={show}
              page={"/telimciler"}
            />
            <List
              setShow={setShow}
              text={"Kurslar"}
              show={show}
              page={"/kurslar"}
            />
            <List setShow={setShow} text={"Əlaqə"} show={show} page={"/elaqe"} />
          </ul>
        </div>
        <button onClick={() => setModalShow(true)} className="buttonEnd">
          Müraciət et
        </button>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
      {/*Navbar ends*/}
    </header>
  );
};

export default Navbar;
