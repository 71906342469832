import React, { useState, useEffect } from "react";
import "./footer.scss";
import Logo from "../../../assets/images/logoTransparent.png";
import LocationIcon from "../../../assets/images/locationIcon.svg";
import MailIcon from "../../../assets/images/mailIcon.svg";
import PhoneIcon from "../../../assets/images/phoneIcon.svg";
// import FacebookIcon from '../../../assets/images/facebook.svg'
// import LinkedinIcon from '../../../assets/images/linkedin.svg'
import InstagramIcon from "../../../assets/images/Instagram.svg";
// import YoutubeIcon from '../../../assets/images/youtube.svg'
import {useNavigate} from "react-router-dom"
import MyVerticallyCenteredModal from "../../Modal/CenteredModal";


function Footer() {
  const navigate = useNavigate()
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const changePage = () => {
      window.scrollTo({ top: 0 });
    };
    changePage()
  });
  return (
    <footer>
      <div className="container">
        <section className="logo">
          <img src={Logo} alt="logo" />
          <p>
            Turizm mütəxəssisləri yetişdiririk. Azərbaycan Turizm Agentlikləri
            Assosiasiyası (ATAA) üzvlərinə xüsusi qiymətlər.
          </p>
          <div className="social-icons">
            <p>Bizi izləməyi unutmayın -</p>
            <img onClick={() => window.open("https://www.instagram.com/turizm.edu.az/", "_self")} src={InstagramIcon} alt="socialIcon" />
          </div>
        </section>
        <section className="courses">
          <h3>Kurslar</h3>
          <ul>
            <li onClick={() => navigate("/kurslar/turmenecer")}>Tur menecer (getmə) proqramı</li>
            <li onClick={() => navigate("/kurslar/viza")}>Viza mütəxəssisi (bütün dünya)</li>
            <li onClick={() => navigate("/kurslar/tezlikle")}>Gəlmə turizm</li>
            <li onClick={() => navigate("/kurslar/daxiliturizm")}>Daxili turizm</li>
            <li onClick={() => navigate("/kurslat/tezlikle")}>Turizmdə mühasibatlıq</li>
            <li onClick={() => navigate("/kurslar/tezlikle")}>Turizmdə marketinq</li>
            <li onClick={() => navigate("/kurslar/tezlikle")}>Turizmdə layihələrin idarə edilməsi</li>
            <li onClick={() => navigate("/kurslar/tezlikle")}>Turizmdə satış texnikaları</li>
          </ul>
        </section>
        <section className="menu-list">
          <h3>Menu</h3>
          <ul>
            <li onClick={() => navigate("/partnyorlar")}>Partnyorlar</li>
            <li onClick={() => navigate("/haqqimizda")}>Haqqımızda</li>
            <li onClick={() => navigate("/telimciler")}>Təlimçilərimiz</li>
            <li onClick={() => navigate("/kurslar")}>Kurslar</li>
            <li onClick={() => navigate("/elaqe")}>Əlaqə</li>
            <li onClick={() => setModalShow(true)}>Müraciət et</li>
            <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
          </ul>
        </section>
        <section className="contact">
          <h3>Əlaqə</h3>
          <div className="contact-info">
            <img src={LocationIcon} alt="icon" />
            <a href="https://www.google.com/maps/d/viewer?msa=0&mid=1li8r6bm-jRoyDs_cBhcQz4N6TlI&ll=40.385731999999976%2C49.82831&z=17">Cəfər Cabbarlı küçəsi 44, Caspian Plaza</a>
          </div>
          <div className="contact-info">
            <img src={MailIcon} alt="icon" />
            <a href="mailto:office@turizm.edu.az">office@turizm.edu.az</a>
          </div>
          <div className="contact-info">
            <img src={PhoneIcon} alt="icon" />
            <a href="tel:+994-55-212-51-61">+994 55 212 49 59 </a>
          </div>
        </section>
      </div>
      <section className="site-rights">
        <p>© Turizm Edu Az 2022.</p>
        <p>Bütün hüquqlar qorunur...</p>
      </section>
    </footer>
  );
}

export default Footer;
