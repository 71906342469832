import React from 'react'
import Slider from "../../components/Swiper/Slider";
import Courses from "../../components/Sections/Courses";
import About from "../../components/Sections/About"
import Education from "../../components/Sections/Education";
import News from "../../components/Sections/News";

function Home() {
  return (
    <main id="home">
      <Slider />
      <Courses />
      <About />
      <Education />
      <News />
    </main>
  )
}

export default Home