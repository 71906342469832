import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, EffectFade } from 'swiper';
import MyVerticallyCenteredModal from '../Modal/CenteredModal';
import pngOne from '../../assets/images/26366782.png'
import pngTwo from '../../assets/images/Job-Work-PNG-File.png'
import './Slider.scss';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/autoplay';
import 'swiper/scss/effect-fade';
import { useNavigate } from "react-router-dom";


const Slider = () => {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate()

  return (
    <div className='mainSlider'>
      <div className='mobileSlider'>
        <p>Dayan, axtardığını burada tapacaqsan!</p>
        <img src={pngTwo} alt="pic" />
        <button onClick={() => navigate("/haqqimizda")}>Ətraflı</button>
      </div>
      <Swiper
        className='swiperSlide'
        modules={[Pagination, Autoplay, EffectFade]}
        effect="fade"
        autoplay={{ delay: 5000 }}
        pagination={{ clickable: true }}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <div className='sliderBackground'>
            <div className='sliderMain'>
              <p>Gələcəyini bizimlə qur.</p>
              <span>"Turizm.Edu.Az" komandası olaraq həyatımızın böyük və gözəl parçasını turizm işini öyrənməyə həsr etmişik. İndi isə öyrəndiyimiz bütün praktik və nəzəri bilikləri Səninlə paylaşmaq, Sənə turizm sferasında səviyyəli xidmət göstərməyi öyrətmək ən böyük amalımızdır.</span>
              <button onClick={() => setModalShow(true)}>Müraciət et</button>
              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
            <img className='pic' src={pngOne} alt="pic" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='sliderBackground'>
            <div className='sliderMain'>
              <p>Dayan, axtardığını burada tapacaqsan!</p>
              <ul style={{paddingLeft: "0px"}}> Kursu bitirən tələbələrə verilən üstünlüklər:
                <li>1 nəfərlik aviabilet hədiyyə - (qrup lideri)</li>
                <li>CV bankında şəxsi kabinet - (sertfikat alan tələbələr)</li>
                <li>1 həftəlik infotur hədiyyə - (bütün tələbələr)</li>
                <li>Turmenecer klubuna üzvlük - (bütün tələbələr)</li>
              </ul>
              <button onClick={() => navigate("/haqqimizda")}>Ətraflı</button>
            </div>
            <img className='pic' src={pngTwo} alt="pic" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Slider