import axios from "axios";

export const SendEmail = async ({
  nameSurname,
  userEmail,
  userPhone,
  selectedCourse,
  seminar,
  promoCode,
  industry,
  membership,
  setSend,
}) => {
  try {
    const datas = { nameSurname, userEmail, userPhone, selectedCourse, seminar, promoCode, industry, membership };
    // let res = await axios.post(`http://localhost:8443/send`, datas);
    // if (res) {
    //   setSend(res.data);
    // }
    fetch('http://localhost:5000/send',{
      method:'POST',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify(datas) 
    })
    .then((response) => response.json())
    .then(responseJson => {
      console.log(responseJson)
    })
  } catch (error) {
    console.log(error,'xxx')
    alert(error);
  }
};