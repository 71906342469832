import React from 'react'
import styles from "./Sections.module.scss"
import Teachers from './Teachers'

const Education = () => {
  return (
    <div className={styles.education}>
      <h2>Tədris</h2>
      <p>Təlimçilərimiz</p>
      <Teachers/>
    </div>
  )
}

export default Education