import {Routes, Route} from 'react-router-dom'

// layout
import Layout from './components/layout/Layout'

// pages
import Home from './pages/home/Home';
import Course from './pages/Courses/Course';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Sponsors from './pages/Sponsors/Sponsors';
import TeacherPage from './pages/Teachers/TeacherPage';
import CoursePage from './pages/Courses/CoursePage'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout/>}> 
          <Route index element={<Home/>}/>
          <Route path="kurslar" element={<Course/>}>
            <Route path=':id' element={<CoursePage/>}/>
          </Route>
          <Route path="haqqimizda" element={<About/>}/>
          <Route path="elaqe" element={<Contact/>}/>
          <Route path="partnyorlar" element={<Sponsors/>}/>
          <Route path="telimciler" element={<TeacherPage/>}/>
        </Route>
      </Routes>
    </>
  );
}

export default App;
