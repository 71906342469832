import React, { useEffect } from 'react'
import "./CoursePage.scss"
import SideMenu from '../../components/SideMenu/SideMenu';
import { useNavigate, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";

const Course = () => {
  const navigate = useNavigate()
  const params = useParams()

  
  useEffect(() => {
    const changePage = () => {
      window.scrollTo({ top: 0 });
    };
    changePage()


  }, []);


  useEffect(() => {
    if(!params.id){
      navigate("/kurslar/turmenecer")
    }
  }, [navigate, params]);




  return (
    <div id='Course'>
      <SideMenu/>
      <Outlet/>
    </div>
  )
}

export default Course